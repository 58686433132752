import React, { useRef, useState } from 'react'
import videoServicios from "../assets/video_servicios_esc.webm"
import { faArrowLeft, faArrowRight, faBuildingShield, faBullhorn, faClipboard, faPause, faPlay, faStar } from '@fortawesome/free-solid-svg-icons'
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import t1 from "../assets/valeria_arbulu_esc.jpeg"
import t2 from "../assets/rodrigo_scerpella_esc.jpeg"
import t3 from "../assets/araceli_esc.jpeg"
import Footer from '../components/footer'
import video_nuevo from "../assets/video_nuevo_landing.webm"
import sierraMuebles from "../assets/sierra.png"
import pacificControl from "../assets/pacific_control.webp"
import laMontanara from "../assets/la_montanara.png"
import nextCode from "../assets/next_code.png"
import sraZapata from "../assets/sra_zapata.jpg"
import { Swiper,SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import maiteSebas from "../assets/maite_sebas.jpg"

const listaServicios = [
    {
        name:"Programas Estratégicos",
        content:"Ofrecemos cursos adaptados a tus necesidades, incluyendo talleres, charlas y capacitaciones, diseñados para impulsar el crecimiento sostenible y fortalecer la competitividad de tu organización.",
        icon:faBuildingShield
    },
    {
        name:"Diagnóstico Organizacional",
        content:"Analizamos a fondo el clima y cultura organizacional, para conocer la estructura y procesos que nos permitan desarrollar una solución única para su empresa.",
        icon:faClipboard
    },
    {
        name:"Mediación de Conflictos",
        content:"Actuamos como facilitadores imparciales para restaurar el diálogo, promover acuerdos y mejorar la dinámica laboral en tu organización.",
        icon:faBuildingShield
    },
    {
        name:"Reclutamiento y Selección de Personal",
        content:"Nuestro enfoque estratégico y clínico, asegura la selección de los mejores profesionales, alineados con la cultura y objetivos de tu negocio.",
        icon:faPersonCircleCheck
    },
    {
        name:"Consejería Laboral",
        content:"Ofrecemos orientación personalizada para mejorar su desempeño, fomentar el crecimiento personal y fortalecer el ambiente laboral.",
        icon:faBullhorn
    }
]

const testimonios = [
    {
        name:"Valeria Arbulú",
        job:"Gerente de Recursos Humanos",
        enterprise:"Sierra Muebles",
        img:t1,
        comment:"Me pareció un servicio bastante completo porque se dedicaron de lleno a cada uno de los colaboradores. Les dieron el tiempo que necesitaban y les abrieron el espacio al diálogo sin limitaciones."
    },
    {
        name:"Rodrigo Scerpella",
        job:"Business developer",
        enterprise:"Pacific",
        img:t2,
        comment:"El principal cambio que veo es que las personas automáticamente entendieron que todo el trabajo que realizamos con la consultora es para su beneficio profesional y personal."
    },
    {
        name:"Araceli",
        job:"Supervisora de laboratorio",
        enterprise:"Pacific",
        img:t3,
        comment:"Su trabajo es muy personalizado, efectivo y profesional. Toman a la empresa como un socio aliado y se involucran en cada paso del proceso. Además, consideran y valoran a cada uno de los colaboradores de forma integral."
    }
]

const preguntasFrecuentes = [
    {
        pregunta:"¿Cómo manejan la búsqueda y selección de personal?",
        respuesta:"En ESC, seguimos un proceso personalizado para la búsqueda y selección de personal, que incluye la identificación de competencias clave, entrevistas psicológicas y evaluaciones psicométricas. Esto nos permite asegurar que cada candidato no solo cumpla con los requisitos técnicos, sino también con la cultura y valores de su empresa, optimizando la integración y el rendimiento a largo plazo."
    },
    {
        pregunta:"¿Qué resultados han obtenido con otras empresas?",
        respuesta:"Hemos ayudado a diferentes empresas a mejorar su productividad y retención de talento a través de procesos de selección efectivos y estrategias de desarrollo organizacional. Nuestros clientes han experimentado una reducción en la rotación de personal, un mejor clima laboral y equipos de trabajo más cohesionados, lo que impacta directamente en sus resultados financieros y operativos."
    },
    {
        pregunta:"¿Cómo pueden ayudarme a mejorar el clima laboral?",
        respuesta:"Utilizamos herramientas avanzadas de medición de clima y cultura organizacional para identificar áreas de mejora y diseñar planes de acción específicos. A través de intervenciones personalizadas, fomentamos la comunicación, la colaboración y el bienestar de los empleados, lo que se traduce en un entorno de trabajo más armonioso, motivado y productivo."
    },
    {
        pregunta:"¿Tienen experiencia en gestión de crisis laborales?",
        respuesta:"Sí, contamos con amplia experiencia y profesionales especializados que se encargan de la gestión en crisis laborales. Nuestra metodología incluye diagnóstico rápido, mediación de conflictos y el desarrollo de estrategias de comunicación y resolución que permiten superar momentos críticos y restaurar el equilibrio dentro de la organización, asegurando la continuidad operativa y la moral del equipo."
    },
    {
        pregunta:"¿Qué tipos de evaluaciones psicológicas o psicométricas utilizan?",
        respuesta:"En ESC, utilizamos una variedad de herramientas psicométricas y evaluaciones psicológicas validadas, que incluyen pruebas de personalidad, habilidades cognitivas y de desempeño. Estas evaluaciones nos permiten obtener una visión integral del candidato, ayudando a tomar decisiones más acertadas para el ajuste tanto al puesto como a la cultura organizacional."
    },
    {
        pregunta:"¿Realizan encuestas de satisfacción laboral?",
        respuesta:"Sí, realizamos encuestas de satisfacción laboral personalizadas, diseñadas para medir la percepción de los empleados sobre diversos aspectos del entorno laboral. Los resultados obtenidos nos permiten generar diagnósticos precisos y desarrollar planes de acción enfocados en mejorar la satisfacción y el compromiso del equipo."
    }
]

const nuestrosClientes = [sierraMuebles,pacificControl,laMontanara,nextCode,sraZapata]

function LandingPage() {

    const videoRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const videoRef2 = useRef(null)
    const [isPlaying2, setIsPlaying2] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [isStart,setIsStart] = useState(true);
    const [isEnd,setIsEnd] = useState(false);

    const togglePlay = () => {
        if(videoRef.current) {
            if(isPlaying) {
                videoRef.current.pause()
            } else {
                videoRef.current.play()
            }
            setIsPlaying(!isPlaying)
        }
    }

    const togglePlay2 = () => {
        if(videoRef2.current) {
            if(isPlaying2) {
                videoRef2.current.pause()
            } else {
                videoRef2.current.play()
            }
            setIsPlaying2(!isPlaying2)
        }
    }

  return (
    <div className='overflow-x-hidden'>
        {/* PROBLEM */}
        <main className='w-screen min-h-dvh flex lg:justify-evenly lg:flex-row flex-col justify-center items-center lg:gap-0 gap-10 p-10 bg-azul-oscuro'>
            <div className='w-fit h-full flex justify-center items-center flex-shrink'>
                <div className='w-[550px] space-y-5'>
                    <h1 className='font-lexend-bold sm:text-6xl text-4xl text-white'>Somos tu aliado</h1>
                    <h1 className='font-lexend-bold sm:text-5xl text-3xl text-white'>estratégico en</h1>
                    <h3 className='font-lexend-bold sm:text-5xl text-3xl bg-white text-azul-oscuro p-1 w-fit'>formación de líderes</h3>
                    <a href='https://api.whatsapp.com/send?phone=51920188134&text=Hola%2C%20estoy%20interesado%20en%20los%20servicios%20de%20ESC' target='_blank' rel='noopener noreferrer' className='font-lexend text-azul-oscuro bg-amarillo text-2xl px-5 py-2 block w-fit rounded-md transition-all ease-in-out duration-300 hover:bg-azul-oscuro hover:text-amarillo '>CONTÁCTANOS</a>
                </div>
            </div>
            <div className='flex-none w-fit h-[600px] relative group lg:m-0 m-5'>
                <video
                    ref={videoRef}
                    id="myVideoServicio"
                    loop
                    className="h-full relative cursor-pointer"
                    onClick={togglePlay}
                >
                    <source src={video_nuevo} type='video/webm'/>
                    Tu navegador no soporta el elemento del video
                </video>
                <button className='absolute rounded-full w-20 h-20 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 opacity-0 text-3xl text-black bg-gray-100 bg-opacity-40 group-hover:opacity-100 flex justify-center items-center transition-all duration-300 ease-in-out cursor-pointer' onClick={togglePlay}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay}/>
                </button>
            </div>
        </main>
        {/* SERVICIOS */}
        <section className='space-y-5 pt-10'>
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>Conoce nuestros servicios</h2>
            <p className='font-arimo leading-snug text-xl text-azul-oscuro opacity-80 px-5 text-center max-w-[500px] mx-auto'>ESC ofrece servicios dedicados a ayudar a todas las empresas en sus ámbitos laborales, con el fin de hacer los procesos más eficientes y productivos.</p>
            <aside className='py-10 flex justify-center items-stretch gap-10 flex-wrap lgs:w-[1302px] mds:w-[868px] sms:w-[434px] w-full mx-auto max-w-[1302px]'>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    grabCursor={true}
                    modules={[Navigation,Pagination,Autoplay]}
                    navigation={{
                        nextEl:".custom-next",
                        prevEl:".custom-prev"
                    }}
                    pagination={{
                        clickable:true,
                    }}
                    className='swiper-servicios-landing'
                    breakpoints={{
                        1302:{slidesPerView:3},
                        868:{slidesPerView:2},
                        434:{slidesPerView:1}
                    }}
                    style={{
                        paddingTop:"30px",
                        paddingBottom: '30px',
                        position:"relative"
                    }}
                    speed={600}
                    autoplay={{
                        delay:6000,
                        disableOnInteraction:false
                    }}
                    onSlideChange={(swiper) => {
                        setActiveIndex(swiper.activeIndex)
                        setIsStart(swiper.isBeginning)
                        setIsEnd(swiper.isEnd)
                    }}
                    onInit={(swiper) => {
                        setIsStart(swiper.isBeginning)
                        setIsEnd(swiper.isEnd)
                    }}
                >
                    <button className={`absolute -translate-x-1/2 -translate-y-1/2 w-16 h-16 text-3xl custom-prev  top-1/2 rounded-full border-2 ${activeIndex % 2 === 0 ? "border-azul-oscuro":"border-amarillo"} ${isStart ? "bg-slate-200 text-slate-700":"bg-azul-claro text-azul-oscuro"}  hover:scale-110 transition-all duration-300 z-20 left-[calc(50%_-_192px)]`} aria-label='Servicio previo'
                    >
                        <FontAwesomeIcon icon={faArrowLeft}/>
                    </button>
                    <button className={`absolute -translate-x-1/2 -translate-y-1/2 w-16 h-16 text-3xl custom-next  top-1/2 rounded-full border-2 ${activeIndex % 2 === 0 ? "border-azul-oscuro":"border-amarillo"} ${isEnd ? "bg-slate-200 text-slate-700":"bg-azul-claro text-azul-oscuro"}  hover:scale-110 transition-all duration-300 z-20 left-[calc(50%_+_192px)]`} aria-label='Servicio posterior'
                    >
                        <FontAwesomeIcon icon={faArrowRight}/>
                    </button>
                    {listaServicios.map((val,i) => (
                        <SwiperSlide
                            style={{ width: '384px' }}
                            key={i}
                            className='z-10 relative'
                        >
                            <div className={`w-96 rounded-full aspect-square p-10 ${ i % 2 === 0 ? "bg-azul-oscuro text-amarillo":"bg-amarillo text-azul-oscuro"}  font-lexend-bold flex justify-center items-center flex-col text-4xl text-center gap-10 ${i === activeIndex ? "opacity-100":"opacity-50 scale-90"} transition-all duration-300 mx-auto`}>
                                <span className='text-5xl'>
                                    <FontAwesomeIcon icon={val.icon} />
                                </span>
                                <h2>{val.name}</h2>
                            </div>
                        </SwiperSlide>
                    ))}
                    <div className='h-10'></div>
                </Swiper>
            </aside>
        </section>
        <section className='py-10 px-5 max-w-[1200px] mx-auto border-y-2 border-azul-oscuro gap-5 flex justify-stretch md:flex-row flex-col'>
            <div className='flex-none w-fit h-[600px] relative group md:mx-0 mx-auto'>
                <video
                    ref={videoRef2}
                    id="myVideoESC"
                    loop
                    className="h-full relative cursor-pointer"
                    onClick={togglePlay2}
                >
                    <source src={videoServicios} type='video/webm'/>
                    Tu navegador no soporta el elemento del video
                </video>
                <button className='absolute rounded-full w-20 h-20 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 opacity-0 text-3xl text-black bg-gray-100 bg-opacity-40 group-hover:opacity-100 flex justify-center items-center transition-all duration-300 ease-in-out cursor-pointer' onClick={togglePlay2}>
                    <FontAwesomeIcon icon={isPlaying2 ? faPause : faPlay}/>
                </button>
            </div>
            <div className='flex flex-col justify-stretch gap-5'>
                <div className='flex-1'>
                    <h2 className='font-lexend-bold text-azul-oscuro lg:text-5xl md:text-4xl text-3xl'>
                    Potenciamos el clima y cultura que tu empresa necesita, a través de soluciones que se enfoquen en la salud mental
                    </h2>
                </div>
                <div className='flex-1'>
                    <h2 className='font-arimo text-lg py-2 border-b-2 border-azul-oscuro text-azul-oscuro text-right'>
                        ¿Quienes Somos?
                    </h2>
                    <div className='flex justify-between items-stretch pt-5 gap-5'>
                        <p className='font-arimo text-base text-azul-oscuro max-w-64'>
                        Servicio de atención clínica a través de «consejería de urgencia» y «departamento de mediación de conflictos»
                        <br/>
                        Enfoque personalizado y adaptado a las necesidades y exigencias legales de cada empresa.
                        </p>
                        <img src={maiteSebas} alt='ESC Quienes Somos' className='aspect-square h-64 object-cover' />
                    </div>
                </div>
            </div>
        </section>
        <section className='py-10 space-y-5'>
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>¿Qué dicen de nosotros?</h2>
            <p className='font-arimo leading-snug text-xl text-azul-oscuro opacity-80 px-5 text-center max-w-[500px] mx-auto'>
                Hemos trabajado de manera ejemplar con nuestros clientes de tal manera que la eficiencia y productividad de sus empresas a aumentado con creces.
            </p>
            <section className='p-5 flex justify-center items-stretch gap-10 flex-wrap'>
                {testimonios.map((val,i) => (
                    <div className='w-96 shadow-md rounded-lg py-5 flex flex-col justify-between border-2 border-dashed border-celeste'>
                        <div className='flex items-center justify-start gap-5 p-3'>
                            <img src={val.img} alt={val.name} className='w-20 aspect-square p-1 border-dotted border-2 border-amarillo rounded-full' />
                            <div>
                                <h3 className='font-lexend-bold text-azul-oscuro'>{val.name}</h3>
                                <p className='font-lexend text-azul-oscuro'>{val.job} <br/> <span className='text-azul-claro'>{val.enterprise}</span></p>
                            </div>
                        </div>
                        <p className='p-5 text-azul-oscuro font-arimo'>
                            {val.comment}
                        </p>
                        <div className='text-amarillo px-5'>
                            {[1,2,3,4,5].map((val,i) => (
                                <FontAwesomeIcon icon={faStar} />
                            ))}
                        </div>
                    </div>
                ))}
            </section>
        </section>
        <section className="py-10 px-5">
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>Nuestros Clientes</h2>
            <section className='py-10 flex justify-center items-center gap-10 flex-wrap'>
                {nuestrosClientes.map((val,i) => (
                    <img key={i} alt='Clientes ESC CONSULTORA
                    '  className='sm:w-64 w-60' src={val}/>
                ))}
            </section>
        </section>
        {/* <section className='py-10 px-5'>
            <h2 className='font-lexend-bold text-4xl text-center mt-5 text-azul-oscuro px-5'>Preguntas Frecuentes</h2>
            <section className='flex justify-center items-stretch flex-wrap gap-5 py-10'>
                {preguntasFrecuentes.map((val,i) => (
                    <div key={i} className='w-96 rounded-md shadow-md border-2 border-amarillo border-dashed p-5'>
                        <h2 className='font-lexend-bold text-2xl text-azul-oscuro py-5 border-b-2 border-azul-oscuro'>{val.pregunta}</h2>
                        <p className='font-arimo text-lg py-5 text-azul-claro'>{val.respuesta}</p>
                    </div>
                ))}
            </section>
        </section> */}
        <Footer/>
    </div>
  )
}

export default LandingPage